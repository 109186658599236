import styled from "styled-components"

const BlogSinlgeStyled = styled.div`
  .container__category {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 65px 0 35px;
  }
  .singlePost {
    &__container {
      max-width: 850px;
      margin: 0 auto;
    }
    &__category {
      margin: 0;
      margin-right: 15px;
      font-weight: 300;
      font-size: 18px;
      margin: 0px 15px 20px 0px;
      @media (max-width: 1080px) {
        margin-bottom: 20px;
      }
      &__item {
        margin: 0;
        margin-right: 15px;
        padding: 8px 25px;
        border: 1px solid #000;
        font-size: 17px;
        font-weight: 500;
        margin: 0px 15px 20px 0px;
        transition: 0.3s all;
        @media (max-width: 1080px) {
          margin-bottom: 20px;
          padding: 5px 12px;
        }
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
    &__meta {
      display: flex;
      justify-content: center;
    }
    &__author {
      font-size: 16px;
      margin-right: 5px;
    }
    &__date {
      font-size: 16px;
      font-weight: 300;
    }
    &__img {
      background: url(${props => props.image});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 400px;
      @media (max-width: 1080px) {
        height: 300px;
      }
      @media (max-width: 880px) {
        height: 250px;
      }
    }
    &__wrapper {
      padding-top: 55px;
      display: flex;
      @media (max-width: 700px) {
        flex-direction: column;
      }
    }
    &__share {
      width: 140px;
      padding-right: 60px;
      @media (max-width: 700px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 30px;
      }
      & > div {
        position: sticky;
        top: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-bottom: 30px;
        @media (max-width: 700px) {
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        display: block;
        width: 30px;
        text-align: center;
        margin-bottom: 20px;
        transition: 0.3s all;
        @media (max-width: 700px) {
          margin-bottom: 0;
          margin: 0 10px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      p {
        @media (max-width: 700px) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    &__content {
      width: calc(100% - 190px);
      @media (max-width: 700px) {
        width: 100%;
      }
      &__img {
        margin-left: -40px;
        padding: 0px 0 60px;
        @media (max-width: 700px) {
          margin-left: 0;
        }
        img {
          max-width: calc(100% + 40px);
          @media (max-width: 700px) {
            max-width: 100%;
            margin-left: 0;
          }
        }
      }
      &__description {
        padding-bottom: 60px;
        h2 {
          font-size: 24px;
          font-weight: 700;
          padding-bottom: 30px;
          padding-top: 20px;
          margin: 0;
        }
        p {
          font-size: 17px;
          padding-bottom: 30px;
          margin: 0;
        }
        ul {
          padding-left: 20px;
          list-style-type: disc;
          margin: 0;
          padding-bottom: 30px;
        }
        ol {
          padding-left: 20px;
          list-style-type: decimal;
          margin: 0;
          padding-bottom: 30px;
        }
      }
    }
    &__authore {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-bottom: 70px;
      a {
        display: flex;
        align-items: flex-end;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 20px;
        bottom: 0;
        left: 0;
        height: 1px;
        background: #d8d8d8;
        @media (max-width: 500px) {
          content: unset;
        }
      }
      & > div {
        max-width: 370px;
        @media (max-width: 500px) {
          max-width: 100%;
        }
      }
      &__data {
        padding-right: 40px;
        @media (max-width: 500px) {
          padding-right: 0;
        }
      }
      &__name {
        margin: 0;
        font-size: 19px;
        font-weight: 500;
      }
      &__postion {
        font-size: 17px;
        font-weight: 300;
        margin: 0;
        padding-bottom: 20px;
      }
      &__email {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
      }
      &__linkedin {
        margin: 0;
        transition: 0.3s all;
        &:hover {
          opacity: 0.5;
        }
      }
      img {
        padding-top: 30px;
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }
`

export default BlogSinlgeStyled
